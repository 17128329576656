<template>
  <div class="ganhadores">
    <Voltar />

    <p>Veja quanta gente já levou os super prêmios para casa</p>

    <form class="ganhadores__form" action="">
      <input id="lista" type="radio" name="ganhador" checked />
      <label for="lista">Ganhadores</label>
      <input id="dep" type="radio" name="ganhador" />
      <label for="dep">Depoimentos</label>

      <div class="ganhadores__lista">
        <article class="ganhadores__lista--item" v-for="ganhador in state.ganhadores" :key="ganhador.id">
          <p><span>Promoção: </span> {{ ganhador.promocao }}</p>
          <p><span>Programa:</span> {{ ganhador.programa }}</p>
          <p><span>Data do sorteio: </span> {{ ganhador.data_sorteio }}</p>
          <p><span>Horário do sorteio: </span> {{ ganhador.horario_sorteio }}</p>
          <p><span>Cupom: </span> {{ ganhador.cupom }}</p>
          <p><span>Cel: </span> {{ ganhador.celular }}</p>
          <p><span>CPF: </span> {{ ganhador.cpf }}</p>
          <p>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264.87 264.87">
              <title>premio-ok</title>
              <g id="Camada_2" data-name="Camada 2">
                <g id="Camada_1-2" data-name="Camada 1">
                  <path
                    class="cls-1"
                    d="M9.46,208.11l24.59-39.73c14.19-21.76,37.84-35.95,64.32-35.95h62.43A38,38,0,0,1,123,170.27H104.05"
                  />
                  <path
                    class="cls-1"
                    d="M123,170.27h75.68L210,155.14a56.45,56.45,0,0,1,45.41-22.7h0l-25.54,45.41c-13.24,24.59-39.73,39.73-67.16,39.73h0A225.34,225.34,0,0,0,37.84,255.41h0"
                  />
                  <rect
                    class="cls-1"
                    x="66.22"
                    y="47.3"
                    width="151.35"
                    height="37.84"
                  />
                  <path
                    class="cls-1"
                    d="M141.89,47.3H125.81a21.41,21.41,0,0,1-21.76-21.76h0A16.58,16.58,0,0,1,120.14,9.46h0a21.41,21.41,0,0,1,21.76,21.76Z"
                  />
                  <path
                    class="cls-1"
                    d="M141.89,47.3H158a21.41,21.41,0,0,0,21.76-21.76h0A16.58,16.58,0,0,0,163.65,9.46h0a21.41,21.41,0,0,0-21.76,21.76Z"
                  />
                  <polyline
                    class="cls-1"
                    points="83.24 132.43 83.24 85.14 200.54 85.14 200.54 170.27"
                  />
                </g>
              </g>
            </svg>
            <span>Prêmio:</span>
            {{ ganhador.premio }}
          </p>
        </article>
      </div>

      <div class="ganhadores__depoimentos">
        <div class="home__conteudo--item" v-for="depoimento in state.depoimentos" :key="depoimento.id">
          <span>
            <svg
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <title>communication, message, chat, text, talk</title>
              <path
                d="M12,2.41c-6.73,0-12,3.46-12,7.88,0,3.59,3.53,6.63,8.69,7.58l.2,2.33A1.52,1.52,0,0,0,10,21.53a1.61,1.61,0,0,0,.44.06A1.5,1.5,0,0,0,11.59,21l2.36-3C19.8,17.45,24,14.22,24,10.29,24,5.87,18.73,2.41,12,2.41Zm1.36,13.7-.43,0-2.15,2.68-.24-2.7L9.75,16C5.26,15.39,2,13,2,10.29,2,7.1,6.58,4.41,12,4.41S22,7.1,22,10.29C22,13.16,18.2,15.71,13.36,16.11Z"
              />
              <rect x="7" y="9.41" width="2" height="2" />
              <rect x="11" y="9.41" width="2" height="2" />
              <rect x="15" y="9.41" width="2" height="2" />
            </svg>
            Depoimento / Rede America Prêmios
          </span>
          <iframe class="video-home" :src="'https://player.vimeo.com/video/' + depoimento.video" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
          <p>Depoimento dos ganhadores do Rede America Prêmios</p>
          <!-- <a href="">#Depoimentos</a> -->
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'
import services from '../../services'
import Voltar from '../../components/Voltar'
export default {
  setup () {
    const router = useRouter()
    const toast = useToast()

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      ganhadores: {},
      depoimentos: {}
    })

    async function getGanhadores () {
      try {
        state.isLoading = true
        const { data, errors } = await services.ganhadores.index()

        if (!errors) {
          state.ganhadores = data
          state.isLoading = false
          return
        }

        if (errors.status === 404) {
          toast.error('Ainda não há ganhadores')
        }

        if (errors.status === 401) {
          toast.error('Ainda não há ganhadores')
        }

        if (errors.status === 422) {
          toast.error(errors.statusText)
        }
        state.isLoading = false
      } catch (error) {
        toast.error('Erro ao fazer carregar os Ganhadores!')
        state.isLoading = false
        state.hasErrors = !!error
      }
    }
    getGanhadores()
    async function getDepoimentos () {
      try {
        state.isLoading = true
        const { data, errors } = await services.depoimentos.index()

        if (!errors) {
          state.depoimentos = data
          state.isLoading = false
          return
        }

        if (errors.status === 404) {
          toast.error('Ainda não há Depoimentos')
        }

        if (errors.status === 401) {
          toast.error('Ainda não há Depoimentos')
        }

        if (errors.status === 422) {
          toast.error(errors.statusText)
        }
        state.isLoading = false
      } catch (error) {
        toast.error('Erro ao fazer carregar os Depoimentos!')
        state.isLoading = false
        state.hasErrors = !!error
      }
    }
    getDepoimentos()

    return {
      router,
      state
    }
  },
  components: { Voltar }
}
</script>
