<template>
  <a @click="() => router.push({ name: 'Home' })" class="voltar">
    <svg
      version="1.1"
      id="Camada_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 29 27"
      style="enable-background: new 0 0 29 27"
      xml:space="preserve"
    >
      <g>
        <path
          d="M19,27h-9C4.5,27,0,22.5,0,17v-7C0,4.5,4.5,0,10,0h9c5.5,0,10,4.5,10,10v7C29,22.5,24.5,27,19,27z M10,2c-4.4,0-8,3.6-8,8
                                    v7c0,4.4,3.6,8,8,8h9c4.4,0,8-3.6,8-8v-7c0-4.4-3.6-8-8-8H10z"
        />
      </g>
      <path
        d="M13.9,12.8c-0.6,0.4-0.6,1.3,0,1.7l6.1,3.8v1.9c0,0.8-0.9,1.3-1.5,0.8L7.9,14.4c-0.6-0.4-0.6-1.3,0-1.7l10.6-6.6
                                C19.1,5.7,20,6.2,20,7v1.9L13.9,12.8z"
      />
    </svg>

    Voltar
  </a>
</template>
<script>
import { useRouter } from 'vue-router'
export default {
  setup () {
    const router = useRouter()

    return {
      router
    }
  }
}
</script>
